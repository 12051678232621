import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import GradeIcon from "@mui/icons-material/Grade";
import HdrWeakIcon from "@mui/icons-material/HdrWeak";

import Header from "./header";
import Footer from "./footer";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

const phones = ["+7 495 960-93-82", "+7 929 575-88-98"];
const email = "info@promtek-project.ru";
const theme = createTheme();

const heroImage = {
  title: "О нас",
  description:
    "Инженерно-Консалтинговый Центр «ПРОМТЭКПРОЕКТ» — динамично развивающая компания, специализирующая на предоставлении услуг нефтегазовым и иным промышленным предприятиям.",
  image: "./img/1.jpg",
  imageText: "main image description",
};

const services = [
  "Проектно-изыскательные работы",
  "Разработка проектов технического перевооружения особо-опасных производственных объектов",
  "Разработка специальных разделов проектной документации",
  "Адаптация проектной документации иностранных компаний",
  "Комплексные услуги в сфере промышленной безопасности",
  "Строительный контроль и технический надзор",
  "Служба технического заказчика, управление проектами",
  "Аудит и консультирование",
  "BIM-проектирование и 3D сканирование",
  "Методологическое сопровождение согласований",
];
const principles = [
  "Гибкость и клиентоориентированность",
  "Качество и умение предложить оптимальные решения",
  "Ответственность и надежное партнерство",
  "Результативность и сроки",
];

const Page = () => {
  const renderPrinciples = principles.map((text, i) => {
    return (
      <ListItem key={`${i}`} disablePadding>
        <ListItemIcon sx={{ minWidth: "2rem", color: "#c01718" }}>
          <CheckIcon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  });

  const renderServices = services.map((text, i) => {
    return (
      <ListItem key={`${i}`} disablePadding>
        <ListItemIcon sx={{ minWidth: "2rem", color: "#c01718" }}>
          <HdrWeakIcon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header title="ПРОМТЭКПРОЕКТ" phones={phones} email={email} />
      <main>
        <Paper
          sx={{
            position: "relative",
            mb: 0,
            borderRadius: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                flexShrink: 0,
                backgroundImage: `url(${heroImage.image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                minHeight: "40vh",
                minWidth: "20rem",
              }}
            >
              <img
                style={{ width: "100%", height: "100%", display: "none" }}
                src={heroImage.image}
                alt={heroImage.imageText}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                minWidth: "21rem",
                paddingTop: "2rem",
              }}
            >
              <Typography
                component="h2"
                variant="h3"
                color="inherit"
                gutterBottom
              >
                {heroImage.title}
              </Typography>
              <Typography
                sx={{ padding: "2rem", textAlign: "left" }}
                variant="h5"
                color="inherit"
                paragraph
              >
                {heroImage.description}
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Paper
          sx={{
            position: "relative",
            mb: 0,
            borderRadius: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap-reverse",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                minWidth: "21rem",
                paddingTop: "2rem",
              }}
            >
              <Typography
                component="h2"
                variant="h4"
                color="inherit"
                gutterBottom
                sx={{ margin: "0 2rem" }}
              >
                Наши услуги:
              </Typography>
              <List sx={{ margin: "2rem", maxWidth: "32rem" }}>
                {renderServices}
              </List>
            </Box>
            <Box
              sx={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                flexShrink: 0,
                backgroundImage: `url(${"./img/2.jpg"})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                minHeight: "40vh",
                minWidth: "20rem",
              }}
            >
              <img
                style={{ width: "100%", height: "100%", display: "none" }}
                src={"./img/2.jpg"}
                alt={heroImage.imageText}
              />
            </Box>
          </Box>
        </Paper>
        <Paper
          sx={{
            position: "relative",
            mb: 0,
            borderRadius: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                flexShrink: 0,
                backgroundImage: `url(${"./img/3.jpg"})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                minHeight: "40vh",
                minWidth: "20rem",
              }}
            >
              <img
                style={{ width: "100%", height: "100%", display: "none" }}
                src={"./img/3.jpg"}
                alt={heroImage.imageText}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                minWidth: "21rem",
                paddingTop: "2rem",
              }}
            >
              <Typography
                component="h2"
                variant="h4"
                color="inherit"
                gutterBottom
                sx={{ margin: "0 2rem" }}
              >
                Наши ценности и принципы:
              </Typography>
              <List sx={{ margin: "2rem" }}>{renderPrinciples}</List>
            </Box>
          </Box>
        </Paper>
        <Paper
          sx={{
            position: "relative",
            mb: 0,
            borderRadius: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap-reverse",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                minWidth: "20rem",
              }}
            >
              <Footer
                title="ООО ИКЦ «ПРОМТЭКПРОЕКТ»"
                address="Москва, Пресненская набережная дом 12, 23 этаж"
                phones={phones}
                email={email}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                flexShrink: 0,
                minWidth: "21rem",
              }}
            >
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A41b6a7630d76281806c7946a97d0403546f16a836a7f58f84342ee151b652fa0&amp;source=constructor"
                width="100%"
                height="400"
                frameBorder="0"
              ></iframe>
            </Box>
          </Box>
        </Paper>
      </main>
    </ThemeProvider>
  );
};

export default Page;
