import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Mail, Phone } from "@mui/icons-material";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{ marginTop: "5rem" }}
      align="center"
    >
      {"Copyright © "}
      <Link color="inherit" href="https://promtek-project.ru">
        promtek-project.ru
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Footer(props) {
  const { phones, address, title, email } = props;

  return (
    <Box component="footer" sx={{ py: 6 }}>
      <Container maxWidth="lg">
        <Typography
          variant="h6"
          align="center"
          sx={{ color: "#c01718" }}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Адрес: {address}
        </Typography>
        <Box
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {phones.map((phone) => {
            return (
              <Link
                key={phone}
                href={`tel:${phone}`}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: "0.5rem",
                  marginBottom: "0.25rem",
                  color: "#c01718",
                  flexShrink: 0,
                }}
                underline="none"
              >
                <Phone fontSize="1rem" sx={{ marginRight: "0.25rem" }} />
                {phone}
              </Link>
            );
          })}
          <Link
            key={email}
            href={`mailto:${email}`}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "0.5rem",
              color: "#c01718",
              flexShrink: 0,
            }}
            underline="none"
          >
            <Mail fontSize="1rem" sx={{ marginRight: "0.25rem" }} />
            {email}
          </Link>
        </Box>
        <Copyright />
      </Container>
    </Box>
  );
}

export default Footer;
