import Page from "./modules/page.jsx";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Page />
    </div>
  );
}

export default App;
