import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box } from "@mui/system";
import { Mail } from "@mui/icons-material";

function Header(props) {
  const { title, phones, email } = props;

  return (
    <Toolbar
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        flexWrap: "wrap",
      }}
    >
      <img
        style={{ flex: 1, maxHeight: "3rem", marginTop: "0.5rem" }}
        src={"./img/logo.svg"}
        alt={"ПРОМТЭКПРОЕКТ"}
      />
      <Typography
        component="h1"
        variant="h5"
        color="inherit"
        align="center"
        noWrap
        sx={{ display: "none" }}
      >
        {title}
      </Typography>
      <Box sx={{ flex: 1 }} />
      <Box>
        {phones.map((phone) => {
          return (
            <Link
              key={phone}
              href={`tel:${phone}`}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "0.5rem",
                margin: "0.25rem 0.5rem 0.25rem 0",
                color: "#c01718",
                flexShrink: 0,
              }}
              underline="none"
            >
              <PhoneIcon fontSize="1rem" sx={{ marginRight: "0.25rem" }} />
              {phone}
            </Link>
          );
        })}
        <Link
          key={email}
          href={`mailto:${email}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: "0.5rem",
            margin: "0.25rem 0.5rem 0.25rem 0",
            color: "#c01718",
            flexShrink: 0,
          }}
          underline="none"
        >
          <Mail fontSize="1rem" sx={{ marginRight: "0.25rem" }} />
          {email}
        </Link>
      </Box>
    </Toolbar>
  );
}

export default Header;
